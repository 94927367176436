export default {
  meta_title: 'Demos — PennyLane',
  meta_description:
    'Take a deeper dive into quantum computing by exploring cutting-edge algorithms using PennyLane and quantum hardware. Unlock new possibilities and push the boundaries of quantum research.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/demonstrations/pennylane_demos_hero_illustration.png',
  title: 'Demos',
  text_1: 'Take a deeper dive into quantum computing by exploring cutting-edge algorithms using PennyLane and quantum hardware. Unlock new possibilities and push the boundaries of quantum research.',
  building_with_pennylane: {
    title: ``,
    buttons: [],
  },
  basedOnPaperSection: {
    title: 'Demos based on papers',
    description: 'Explore our expertly crafted research demos, all based on published papers, bringing cutting-edge studies to life. [See how researchers are using PennyLane!](/research)'
  }
}
