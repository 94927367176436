import { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../components/seo'
import DemosSearchInput from '../../components/DemosSearchInput/DemosSearchInput'
import BuildWithPLSection from '../../components/BuildWithPennylaneSection/BuildWithPennylane'
import DemoCategoryList from '../../components/DemoCategoyList/DemoCategoryList'
import demonstrations from '../../content/qml/demonstrations/demonstrations'
import DemosFeaturedCarousel from '../../components/DemosFeaturedCarousel/DemosFeaturedCarousel'
import DemoBasedOnPaperCarousel from '../../components/DemoBasedOnPaperCarousel/DemoBasedOnPaperCarousel'
import LinkComponent from '../../components/LinkComponent/LinkComponent'
import { demosCategories } from '../../../content/demos/demonstrations_categories'
import SearchTag from '../../components/SearchTag/SearchTag'
import {
  ContentType,
  contentSortParams,
  createSearchPageRoute,
} from 'shared-utilities'
import '../../scss/pages/QML/demonstrations.scss'

const { meta_title, meta_description, meta_image } = demonstrations
const { title, buttons } = demonstrations.building_with_pennylane
const HERO_ILLUSTRATION =
  'https://assets.cloud.pennylane.ai/pennylane_website/pages/demonstrations/pennylane_demos_hero_illustration.png'

const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_transparent.png`

const DemosPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <section className="Demonstrations">
      <>
        <div className="Hero__Background__Container">
          <StaticImage
            src={HERO_BACKGROUND}
            alt=""
            loading="eager"
            placeholder="none"
            quality={100}
            objectPosition="top left"
            className="Hero__Background__Image Sticky__Image"
          />

          <div className="Demonstrations__hero">
            <div className="Demonstrations__hero__content">
              <h1 className="mb-5">{demonstrations.title}</h1>
              <h2 className="mb-8 font-roboto font-light text-grey-3 text-xl">
                {demonstrations.text_1}
              </h2>
              <DemosSearchInput />
              <div className="mt-4 text-center md:text-left">
                <p className="mt-8">
                  Choose a category, or have a look at{' '}
                  <LinkComponent href="/qml/demos_community">
                    demos made by our community
                  </LinkComponent>
                  .
                </p>
                <div className="flex flex-wrap gap-2">
                  <SearchTag
                    text="All Demos"
                    href={createSearchPageRoute({
                      contentType: ContentType.DEMO,
                      sort: contentSortParams.PUBLICATION_DATE_DESC,
                    })}
                  />
                  <SearchTag
                    text="Demos based on papers"
                    href={createSearchPageRoute({
                      contentType: ContentType.DEMO,
                      sort: contentSortParams.PUBLICATION_DATE_DESC,
                      isPaper: true,
                    })}
                  />
                  {demosCategories.map((demo) => (
                    <SearchTag
                      key={demo.title}
                      href={createSearchPageRoute({
                        category: demo.title,
                        contentType: ContentType.DEMO,
                        sort: contentSortParams.PUBLICATION_DATE_DESC,
                      })}
                      text={demo.title}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="Demonstrations__hero__illustration">
              <StaticImage
                src={HERO_ILLUSTRATION}
                alt=""
                loading="eager"
                placeholder="none"
                quality={100}
                className="w-full"
                data-testid="demo-hero-image"
              />
            </div>
          </div>
        </div>
        <DemosFeaturedCarousel title="New demos" />
      </>
      <DemoBasedOnPaperCarousel />
      <DemoCategoryList />
      {title && buttons.length ? (
        <BuildWithPLSection title={title} buttons={buttons} />
      ) : null}
    </section>
  )
}

export default DemosPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
