import { graphql, useStaticQuery } from 'gatsby'
import CategoryCarousel from '../CategoryCarousel/CategoryCarousel'
import {
  ContentType,
  contentSortParams,
  createSearchPageRoute,
} from 'shared-utilities'
import LinkComponent from '../LinkComponent/LinkComponent'
import demosContent from '../../content/qml/demonstrations/demonstrations'
import './DemoBasedOnPaperCarousel.scss'

const { basedOnPaperSection } = demosContent

const DemoBasedOnPaperCarousel = () => {
  const demosBasedOnPaperResult =
    useStaticQuery<Queries.GetTenDemosBasedOnPaperQuery>(graphql`
      query GetTenDemosBasedOnPaper {
        pennylaneCloud {
          search(
            input: {
              contentTypes: DEMO
              specificFilters: { demoFilters: { basedOnPapers: true } }
              sorts: { field: publishedAt, desc: true }
            }
            page: { offset: 0, limit: 10 }
          ) {
            items {
              ... on pennylaneCloud_GenericContent {
                id
                title
                slug
                thumbnail
                publishedAt
              }
            }
            totalItems
          }
        }
      }
    `)

  const totalItems = demosBasedOnPaperResult.pennylaneCloud.search.totalItems
  const demos = demosBasedOnPaperResult.pennylaneCloud.search.items as {
    readonly id: string
    readonly title: string
    readonly slug: string
    readonly thumbnail: string | null
  }[]

  return (
    <div>
      <div className="DemoBasedOnPaperCarousel">
        <div className="max-w-[1300px] mx-auto px-3">
          <CategoryCarousel
            title={basedOnPaperSection.title}
            description={basedOnPaperSection.description}
            seeAllLink={
              <LinkComponent
                href={createSearchPageRoute({
                  contentType: ContentType.DEMO,
                  sort: contentSortParams.PUBLICATION_DATE_DESC,
                  isPaper: true,
                })}
                id="demos-based-on-paper"
              >
                See all ({totalItems})
              </LinkComponent>
            }
            categoryList={demos.map((demo) => {
              return {
                link: `/qml/demos/${demo.slug}`,
                label: demo.title,
                image: demo.thumbnail || '',
                slug: demo.slug,
              }
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default DemoBasedOnPaperCarousel
