import ItemsCarousel, {
  IItemsCarouselCard,
} from '../ItemsCarousel/ItemsCarousel'
import LinkComponent from '../LinkComponent/LinkComponent'
import MarkdownText from '../MarkdownText/MarkdownText'
import './CategoryCarousel.scss'

export interface ICategoryCarouselProps {
  title: string | JSX.Element
  seeAllLink: string | JSX.Element
  categoryList?: IItemsCarouselCard[]
  customCarousel?: JSX.Element // Optional key in case other cards need to be displayed instead of ItemsCarousel
  customItemsLength?: number // Optional key in case total displayed cards is different from see all total number
  description?: string
}

const CategoryCarousel = ({
  title,
  description,
  seeAllLink,
  categoryList,
  customCarousel,
  customItemsLength,
}: ICategoryCarouselProps) => {
  return (
    <div className="CategoryCarousel" data-testid="category-carousel">
      <div className="CategoryCarousel__header">
        <h2>{title}</h2>
        {typeof seeAllLink === 'string' ? (
          <LinkComponent
            href={seeAllLink}
            className="CategoryCarousel__SeeAll--desktop"
            data-testid="see-all-link"
          >
            See all (
            {customItemsLength ? customItemsLength : categoryList?.length})
          </LinkComponent>
        ) : (
          <span className="CategoryCarousel__SeeAll--desktop">
            {seeAllLink}
          </span>
        )}
      </div>
      {description && (
        <MarkdownText
          text={description}
          className="CategoryCarousel__description"
        />
      )}

      {customCarousel ? (
        customCarousel
      ) : (
        <ItemsCarousel
          relatedItems={categoryList}
          slidesToShow={4}
          responsive={[
            {
              breakpoint: 1198,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 592,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        />
      )}

      {typeof seeAllLink === 'string' ? (
        <LinkComponent
          href={seeAllLink}
          className="CategoryCarousel__SeeAll--mobile"
        >
          See all (
          {customItemsLength ? customItemsLength : categoryList?.length})
        </LinkComponent>
      ) : (
        <div className="CategoryCarousel__SeeAll--mobile">{seeAllLink}</div>
      )}
    </div>
  )
}

export default CategoryCarousel
